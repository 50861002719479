<template>
  <div
    class="values_management"
    style="overflow: hidden; padding: 5px 10px 10px 10px"
  >
    <div class="header">
      {{ $t("add_values.add_values") }}
    </div>
    <div v-if="!isLoading" class="Container Flipped">
      <table class="Content">
        <tr>
          <th v-for="item in table_header" :key="item.id">{{ item }}</th>
        </tr>
        <tr>
          <td>
            <img
              style="height: 100px; width: 150px"
              :src="`${server_url}/${allValues.image}`"
              alt=""
            />
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              ref="courageDescriptionKA"
              class="short_text"
              style="padding-left: 20px"
            ></div>
            <div
              v-else
              ref="courageDescriptionEN"
              class="short_text"
              style="padding-left: 20px"
            ></div>
          </td>
          <td>
            <div v-if="lang == 'ka'" v-html="allValues.courageTitleKA"></div>
            <div v-else v-html="allValues.courageTitleEN"></div>
          </td>
          <td>
            <img
              style="height: 50px; width: 50px"
              :src="`${server_url}/${allValues.courageImage}`"
              alt=""
            />
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              ref="responsibilityDescriptionKA"
              class="short_text"
              style="padding-left: 20px"
            ></div>
            <div
              v-else
              ref="responsibilityDescriptionEN"
              class="short_text"
              style="padding-left: 20px"
            ></div>
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              v-html="allValues.responsibilityTitleKA"
            ></div>
            <div V-else v-html="allValues.responsibilityTitleEN"></div>
          </td>
          <td>
            <img
              style="height: 50px; width: 50px"
              :src="`${server_url}/${allValues.responsibilityImage}`"
              alt=""
            />
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              ref="supportDescriptionKA"
              class="short_text"
              style="padding-left: 20px"
            ></div>
            <div
              v-else
              ref="supportDescriptionEN"
              class="short_text"
              style="padding-left: 20px"
            ></div>
          </td>
          <td>
            <div v-if="lang == 'ka'" v-html="allValues.supportTitleKA"></div>
            <div v-else v-html="allValues.supportTitleEN"></div>
          </td>
          <td>
            <img
              style="height: 50px; width: 50px"
              :src="`${server_url}/${allValues.supportImage}`"
              alt=""
            />
          </td>
          <td>
            <div
              v-if="lang == 'ka'"
              ref="wiseSolutionsDescriptionKA"
              class="short_text"
              style="padding-left: 20px"
            ></div>
            <div
              v-else
              ref="wiseSolutionsDescriptionEN"
              class="short_text"
              style="padding-left: 20px"
            ></div>
          </td>
          <td>
            <div v-if="lang=='ka'" v-html="allValues.wiseSolutionsTitleKA"></div>
            <div v-else v-html="allValues.wiseSolutionsTitleEN"></div>
          </td>
          <td>
            <img
              style="height: 50px; width: 50px"
              :src="`${server_url}/${allValues.wiseSolutionsImage}`"
              alt=""
            />
          </td>
          <td>
            <b-button
              @click="
                (modalShow = !modalShow),
                  editValues(
                    allValues._id,
                    allValues.image,
                    allValues.courageDescriptionKA,
                    allValues.courageDescriptionEN,
                    allValues.courageTitleKA,
                    allValues.courageTitleEN,
                    allValues.courageImage,
                    allValues.responsibilityDescriptionKA,
                    allValues.responsibilityDescriptionEN,
                    allValues.responsibilityTitleKA,
                    allValues.responsibilityTitleEN,
                    allValues.responsibilityImage,
                    allValues.supportDescriptionKA,
                    allValues.supportDescriptionEN,
                    allValues.supportTitleKA,
                    allValues.supportTitleEN,
                    allValues.supportImage,
                    allValues.wiseSolutionsDescriptionKA,
                    allValues.wiseSolutionsDescriptionEN,
                    allValues.wiseSolutionsTitleKA,
                    allValues.wiseSolutionsTitleEN,
                    allValues.wiseSolutionsImage
                  )
              "
            >
              <i class="fal fa-pen"></i>
            </b-button>
          </td>
        </tr>
      </table>
    </div>
    <div v-else>
      <b-skeleton-table
        :rows="3"
        :columns="7"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>
    <b-modal id="modal-xl-2" v-model="modalShow" size="xl" hide-footer>
      <div class="text_editor">
        <label for="">{{ $t("add_values.courageDescription") }} (KA)</label>
        <vue-editor v-model="update.courageDescriptionKA"></vue-editor>
        <label for="">{{ $t("add_values.courageDescription") }} (EN)</label>
        <vue-editor v-model="update.courageDescriptionEN"></vue-editor>
        <label for="">{{ $t("add_values.courageTitle") }} (KA)</label>
        <vue-editor v-model="update.courageTitleKA"></vue-editor>
        <label for="">{{ $t("add_values.courageTitle") }} (EN)</label>
        <vue-editor v-model="update.courageTitleEN"></vue-editor>
        <div>
          <img
            style="width: 150px; height: 100px"
            :src="`${server_url}/${update.courageImage}`"
            alt="await for new image to upload"
            class="image"
          />
        </div>
        <div>
          <label for="">{{ $t("add_values.courageImage") }}</label>
          <div>
            <input
              type="file"
              style="margin: 10px 0"
              accept="image/jpg, image/png, image/jpeg"
              @change="previewcourageImage($event)"
            />
          </div>
        </div>
      </div>
      <div class="text_editor">
        <label for=""
          >{{ $t("add_values.responsibilityDescription") }} (KA)</label
        >
        <vue-editor v-model="update.responsibilityDescriptionKA"></vue-editor>
        <label for=""
          >{{ $t("add_values.responsibilityDescription") }} (EN)</label
        >
        <vue-editor v-model="update.responsibilityDescriptionEN"></vue-editor>
        <label for="">{{ $t("add_values.responsibilityTitle") }} (KA)</label>
        <vue-editor v-model="update.responsibilityTitleKA"></vue-editor>
        <label for="">{{ $t("add_values.responsibilityTitle") }} (EN)</label>
        <vue-editor v-model="update.responsibilityTitleEN"></vue-editor>
        <div>
          <img
            style="width: 150px; height: 100px"
            :src="`${server_url}/${update.responsibilityImage}`"
            alt="await for new image to upload"
            class="image"
          />
        </div>
        <div>
          <label for="">{{ $t("add_values.responsibilityImage") }}</label>
          <div>
            <input
              type="file"
              style="margin: 10px 0"
              accept="image/jpg, image/png, image/jpeg"
              @change="previewresponsibilityImage($event)"
            />
          </div>
        </div>
      </div>
      <div class="text_editor">
        <label for="">{{ $t("add_values.supportDescription") }} (KA)</label>
        <vue-editor v-model="update.supportDescriptionKA"></vue-editor>
        <label for="">{{ $t("add_values.supportDescription") }} (EN)</label>
        <vue-editor v-model="update.supportDescriptionEN"></vue-editor>
        <label for="">{{ $t("add_values.supportTitle") }} (KA)</label>
        <vue-editor v-model="update.supportTitleKA"></vue-editor>
        <label for="">{{ $t("add_values.supportTitle") }} (EN)</label>
        <vue-editor v-model="update.supportTitleEN"></vue-editor>
        <div>
          <img
            style="width: 150px; height: 100px"
            :src="`${server_url}/${update.supportImage}`"
            alt="await for new image to upload"
            class="image"
          />
        </div>
        <div>
          <label for="">{{ $t("add_values.supportImage") }}</label>
          <div>
            <input
              type="file"
              style="margin: 10px 0"
              accept="image/jpg, image/png, image/jpeg"
              @change="previewsupportImage($event)"
            />
          </div>
        </div>
      </div>
      <div class="text_editor">
        <label for=""
          >{{ $t("add_values.wiseSolutionsDescription") }} (KA)</label
        >
        <vue-editor v-model="update.wiseSolutionsDescriptionKA"></vue-editor>
        <label for=""
          >{{ $t("add_values.wiseSolutionsDescription") }} (EN)</label
        >
        <vue-editor v-model="update.wiseSolutionsDescriptionEN"></vue-editor>
        <label for="">{{ $t("add_values.wiseSolutionsTitle") }} (KA)</label>
        <vue-editor v-model="update.wiseSolutionsTitleKA"></vue-editor>
        <label for="">{{ $t("add_values.wiseSolutionsTitle") }} (EN)</label>
        <vue-editor v-model="update.wiseSolutionsTitleEN"></vue-editor>
        <div>
          <img
            style="width: 150px; height: 100px"
            :src="`${server_url}/${update.wiseSolutionsImage}`"
            alt="await for new image to upload"
            class="image"
          />
        </div>
        <div>
          <label for="">{{ $t("add_values.wiseSolutionsImage") }}</label>
          <div>
            <input
              type="file"
              style="margin: 10px 0"
              accept="image/jpg, image/png, image/jpeg"
              @change="previewwiseSolutionsImage($event)"
            />
          </div>
        </div>
      </div>
      <div>
        <img
          style="width: 150px; height: 100px"
          :src="`${server_url}/${update.image}`"
          alt="await for new image to upload"
          class="image"
        />
      </div>
      <label for="">{{ $t("admin.image") }}</label>
      <div>
        <div>
          <input
            type="file"
            style="margin: 10px 0"
            accept="image/jpg, image/png, image/jpeg"
            @change="previewFiles($event)"
          />
        </div>
      </div>
      <button
        type="submit"
        class="submit btn btn-primary"
        @click="UpdateValues()"
      >
        <span v-show="!isLoading">{{ $t("admin.edit") }}</span>
        <span v-show="isLoading">
          {{ $t("login.loading") }}
          <spinner v-show="isLoading" text="loading" />
        </span>
      </button>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import env from "../../../env.json";
import { VueEditor } from "vue2-editor";
import spinner from "../../spinner/Spinner.vue";
import Swal from "sweetalert2";

export default {
  name: "getCompany",
  components: {
    VueEditor,
    spinner,
  },
  data() {
    return {
      modalShow: false,
      table_header: [
        this.$t("admin.image"),
        this.$t("add_values.courageDescription"),
        this.$t("add_values.courageTitle"),
        this.$t("add_values.courageImage"),
        this.$t("add_values.responsibilityDescription"),
        this.$t("add_values.responsibilityTitle"),
        this.$t("add_values.responsibilityImage"),
        this.$t("add_values.supportDescription"),
        this.$t("add_values.supportTitle"),
        this.$t("add_values.supportImage"),
        this.$t("add_values.wiseSolutionsDescription"),
        this.$t("add_values.wiseSolutionsTitle"),
        this.$t("add_values.wiseSolutionsImage"),
        this.$t("admin.edit"),
      ],
      allValues: [],
      update: {
        _id: "",
        image: null,
        courageDescriptionKA: "",
        courageDescriptionEN: "",
        courageTitleKA: "",
        courageTitleEN: "",
        courageImage: null,
        responsibilityDescriptionKA: "",
        responsibilityDescriptionEN: "",
        responsibilityTitleKA: "",
        responsibilityTitleEN: "",
        responsibilityImage: null,
        supportDescriptionKA: "",
        supportDescriptionEN: "",
        supportTitleKA: "",
        supportTitleEN: "",
        supportImage: null,
        wiseSolutionsDescriptionKA: "",
        wiseSolutionsDescriptionEN: "",
        wiseSolutionsTitleKA: "",
        wiseSolutionsTitleEN: "",
        wiseSolutionsImage: null,
      },
      server_url: env.server_url,
      isLoading: false,
      lang: "ka",
    };
  },
  mounted() {
    this.getValues();
    if (localStorage.getItem("lang") == "ka") {
      this.lang = "ka";
    } else {
      this.lang = "en";
    }
  },
  methods: {
    previewFiles(e) {
      const image = e.target.files[0];
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.createBase64Image(image);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.update.image = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    previewcourageImage(e) {
      const image = e.target.files[0];
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.createBase64Image2(image);
    },
    createBase64Image2(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.update.courageImage = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    previewresponsibilityImage(e) {
      const image = e.target.files[0];
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.createBase64Image3(image);
    },
    createBase64Image3(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.update.responsibilityImage = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    previewsupportImage(e) {
      const image = e.target.files[0];
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.createBase64Image4(image);
    },
    createBase64Image4(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.update.supportImage = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    previewwiseSolutionsImage(e) {
      const image = e.target.files[0];
      if (e.target.files[0].size > 1000000) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("errors.too_long"),
        });
      }
      this.createBase64Image5(image);
    },
    createBase64Image5(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.update.wiseSolutionsImage = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    getValues() {
      this.isLoading = true;
      axios.get(`${env.host}/get/informations/values`).then((res) => {
        this.isLoading = false;
        this.$nextTick(() => {
          this.$refs.courageDescriptionKA.insertAdjacentHTML(
            "beforeend",
            this.allValues.courageDescriptionKA
          );
          this.$refs.responsibilityDescriptionKA.insertAdjacentHTML(
            "beforeend",
            this.allValues.responsibilityDescriptionKA
          );
          this.$refs.supportDescriptionKA.insertAdjacentHTML(
            "beforeend",
            this.allValues.supportDescriptionKA
          );
          this.$refs.wiseSolutionsDescriptionKA.insertAdjacentHTML(
            "beforeend",
            this.allValues.wiseSolutionsDescriptionKA
          );
        });
        this.$nextTick(() => {
          this.$refs.courageDescriptionEN.insertAdjacentHTML(
            "beforeend",
            this.allValues.courageDescriptionEN
          );
          this.$refs.responsibilityDescriptionEN.insertAdjacentHTML(
            "beforeend",
            this.allValues.responsibilityDescriptionEN
          );
          this.$refs.supportDescriptionEN.insertAdjacentHTML(
            "beforeend",
            this.allValues.supportDescriptionEN
          );
          this.$refs.wiseSolutionsDescriptionEN.insertAdjacentHTML(
            "beforeend",
            this.allValues.wiseSolutionsDescriptionEN
          );
        });
        this.allValues = res.data.item;
      });
    },
    editValues(
      id,
      image,
      courageDescriptionKA,
      courageDescriptionEN,
      courageTitleKA,
      courageTitleEN,
      courageImage,
      responsibilityDescriptionKA,
      responsibilityDescriptionEN,
      responsibilityTitleKA,
      responsibilityTitleEN,
      responsibilityImage,
      supportDescriptionKA,
      supportDescriptionEN,
      supportTitleKA,
      supportTitleEN,
      supportImage,
      wiseSolutionsDescriptionKA,
      wiseSolutionsDescriptionEN,
      wiseSolutionsTitleKA,
      wiseSolutionsTitleEN,
      wiseSolutionsImage
    ) {
      this.update._id = id;
      this.update.image = image;
      this.update.courageDescriptionKA = courageDescriptionKA;
      this.update.courageDescriptionEN = courageDescriptionEN;
      this.update.courageTitleKA = courageTitleKA;
      this.update.courageTitleEN = courageTitleEN;
      this.update.courageImage = courageImage;
      this.update.responsibilityDescriptionKA = responsibilityDescriptionKA;
      this.update.responsibilityDescriptionEN = responsibilityDescriptionEN;
      this.update.responsibilityTitleKA = responsibilityTitleKA;
      this.update.responsibilityTitleEN = responsibilityTitleEN;
      this.update.responsibilityImage = responsibilityImage;
      this.update.supportDescriptionKA = supportDescriptionKA;
      this.update.supportDescriptionEN = supportDescriptionEN;
      this.update.supportTitleKA = supportTitleKA;
      this.update.supportTitleEN = supportTitleEN;
      this.update.supportImage = supportImage;
      this.update.wiseSolutionsDescriptionKA = wiseSolutionsDescriptionKA;
      this.update.wiseSolutionsDescriptionEN = wiseSolutionsDescriptionEN;
      this.update.wiseSolutionsTitleKA = wiseSolutionsTitleKA;
      this.update.wiseSolutionsTitleEN = wiseSolutionsTitleEN;
      this.update.wiseSolutionsImage = wiseSolutionsImage;
    },
    UpdateValues() {
      this.isLoading = true;

      if (!this.update.image.includes("data:image/")) {
        this.update.image = null;
      }
      if (!this.update.courageImage.includes("data:image/")) {
        this.update.courageImage = null;
      }
      if (!this.update.responsibilityImage.includes("data:image/")) {
        this.update.responsibilityImage = null;
      }
      if (!this.update.supportImage.includes("data:image/")) {
        this.update.supportImage = null;
      }
      if (!this.update.wiseSolutionsImage.includes("data:image/")) {
        this.update.wiseSolutionsImage = null;
      }

      axios
        .post(`${env.host}/edit/informations/values/byImage`, this.update)
        .then(() => {
          Swal.fire({
            icon: "success",
            text: this.$t("success.success_edit"),
          });
          this.isLoading = false;
          this.modalShow = false;
          setTimeout(() => {
            this.getValues();
          }, 1000);
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.values_management {
  width: 100%;
}
.image {
  margin-top: 10px;
}
label {
  font-family: mtavruliBOLD;
  margin-top: 15px;
}

.Container {
  overflow-y: auto;
}
.Flipped,
.Flipped .Content {
  transform: rotateX(180deg);
  -ms-transform: rotateX(180deg); /* IE 9 */
  -webkit-transform: rotateX(180deg); /* Safari and Chrome */
}
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb {
  background: #7e5493;
  border-radius: 0px;
}
.short_text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
button,
.header {
  background: #7e5493;
  border: none;
  padding: 10px 30px 10px 30px;
  border-radius: 0px;
}
.header {
  color: white;
  width: 25%;
  margin-bottom: 10px;
}
i {
  color: white;
}
table {
  overflow-x: scroll;
  width: 100%;
  border-spacing: 0;
  border-radius: 0px;
  overflow: hidden;
}
thead {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
}
th {
  background: #7e5493;
  color: #fff;
}

td:nth-child(1) {
  background: #7e5493;
  color: #fff;
  border-radius: 0px;
}
th,
td {
  padding: 1em;
}
tr,
td {
  display: block;
}
td {
  position: relative;
}
td::before {
  content: attr(data-label);
  position: absolute;
  left: 0;
  padding-left: 1em;
  font-weight: 600;
  font-size: 0.9em;
  text-transform: uppercase;
}
tr {
  margin-bottom: 1.5em;
  border: 1px solid #ddd;
  border-radius: 0px;
  text-align: right;
}
tr:last-of-type {
  margin-bottom: 0;
}
td:nth-child(n + 2):nth-child(odd) {
  background-color: #ddd;
}
@media only screen and (min-width: 768px) {
  thead {
    visibility: visible;
    position: relative;
  }
  th {
    text-align: left;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  tr {
    display: table-row;
    border: none;
    border-radius: 0px;
    text-align: left;
  }
  tr:nth-child(even) {
    background-color: #ddd;
  }
  td {
    display: table-cell;
  }
  td::before {
    content: none;
  }
  td:nth-child(1) {
    background: transparent;
    color: #444;
    border-radius: 0px;
  }
  td:nth-child(n + 2):nth-child(odd) {
    background-color: transparent;
  }
}
.submit {
  width: 100%;
  height: 45px;
  border: none;
  background: #7e5493;
  border-radius: 0px;
  cursor: pointer;
  outline: none;
  color: white;
}
</style>
